<template>
    <div>
            <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
           <v-toolbar flat dense class="tc-title">
                 <router-link to="student-personalDetailsNew" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
                <v-toolbar-title>Address Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                 <router-link to="student-academicsdynamic" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
             </v-toolbar>
           <v-card>
               <v-card-text>
                   <v-row >
                       <v-col cols="12" sm="5"  v-if="local.Local_Add_Dynamic.isActive && local.Local_Add_Dynamic">
                        <span v-if="local.Local_Add_Dynamic.isEditable">
                            <span v-if="local.Local_Add_Dynamic.isRequired">
                                <label>{{ local.Local_Add_Dynamic.display_name }}<span class="required">*</span> :</label>
                                <v-textarea 
                                    outlined
                                    v-model="local.address"
                                    name="input-7-4"
                                    value="local_Add.address"
                                    :rules="[ruless, rules.isEmpty]"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32">
                                    </v-textarea>
                            </span>
                            <span v-else>
                                <label>{{ local.Local_Add_Dynamic.display_name }} :</label>
                                <v-textarea 
                                outlined
                                v-model="local.address"
                                name="input-7-4"
                                value="local_Add.address"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32">
                                </v-textarea>
                            </span>
                        </span>
                        <span v-else>
                            <span><b>{{ local.Local_Add_Dynamic.display_name }}</b><span v-if="local.Local_Add_Dynamic.isRequired" class="required"> *</span> : {{ local.address }}</span>
                        </span>
                            <v-row>
                                <v-col cols="12" sm="6" v-if="local.Local_Pin_Dynamic.isActive && local.Local_Pin_Dynamic">
                                    <span v-if="local.Local_Pin_Dynamic.isEditable">
                                        <span v-if="local.Local_Pin_Dynamic.isRequired">
                                          <label>{{ local.Local_Pin_Dynamic.display_name }}<span class="required" counter="6">*</span> :</label>
                                          <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="local.pin" :rules="[v => !!v || 'Field is required'||/^-?(0|[1-9]\d*)?$/]"></v-text-field>
                                        </span>
                                        <span v-else>
                                            <label>{{ local.Local_Pin_Dynamic.display_name }} :</label>
                                            <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="local.pin"></v-text-field>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <span><b>{{ local.Local_Pin_Dynamic.display_name }}</b><span v-if="local.Local_Pin_Dynamic.isRequired" class="required"> *</span> : {{ local.pin }}</span>
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="6" v-if="local.Local_Country_Dynamic.isActive && local.Local_Country_Dynamic">
                                    <span v-if="local.Local_Country_Dynamic.isEditable">
                                        <span v-if="local.Local_Country_Dynamic.isRequired">
                                            <label>{{ local.Local_Country_Dynamic.display_name }}<span class="required">*</span> :</label>
                                            <v-select
                                            :items="countryList"
                                            v-model="local.cid"
                                            :rules="[v => !!v || 'Field is required']"
                                            item-text="country"
                                            item-value="id"
                                            required
                                            ></v-select>  
                                        </span>  
                                        <span v-else>
                                            <label>{{ local.Local_Country_Dynamic.display_name }} :</label>
                                            <v-select
                                            :items="countryList"
                                            v-model="local.cid"
                                            item-text="country"
                                            item-value="id"
                                            ></v-select>
                                        </span>
                                    </span> 
                                    <span v-else>
                                        <span><b>{{ local.Local_Country_Dynamic.display_name }}</b><span v-if="local.Local_Country_Dynamic.isRequired" class="required"> *</span> : {{ local.cname }}</span>
                                    </span>
                                </v-col>
                            </v-row>
                       </v-col>
                       <v-col cols="12" sm="2" v-if="(permenant.Per_Add_Dynamic.isActive && local.Local_Add_Dynamic.isActive) && (permenant.Per_Add_Dynamic.isEditable && local.Local_Add_Dynamic.isEditable)">
                           <!-- <v-checkbox
                            label="Whether Permanent Address is same as Local Address"
                            required
                            v-model="checkbox"
                            @click="copyadd()"
                            ></v-checkbox> -->
                            <h4><input type="checkbox"
                          v-model="checkbox"
                          color="success" style="margin: 0.4rem; width:18px; height: 15px;" @click="copyadd()"
                          />&nbsp;&nbsp;Whether Permanent Address is same as Local Address</h4>
                       </v-col>
                       <v-col cols="12" sm="5" v-if="permenant.Per_Add_Dynamic.isActive && permenant.Per_Add_Dynamic">
                        <span v-if="permenant.Per_Add_Dynamic.isEditable">
                            <span v-if="permenant.Per_Add_Dynamic.isRequired">
                                <label>{{ permenant.Per_Add_Dynamic.display_name }}<span class="required">*</span> :</label>
                                <v-textarea 
                                outlined
                                :rules="[ruless, rules.isEmpty]"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32"
                                v-model="permenant.address"
                                name="input-7-4"
                                value="local_Add.address">
                                </v-textarea>
                            </span>
                            <span v-else>
                                <label>{{ permenant.Per_Add_Dynamic.display_name }} :</label>
                                <v-textarea 
                                outlined
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 44 || event.charCode == 38 || event.charCode == 32"
                                v-model="permenant.address"
                                name="input-7-4"
                                value="local_Add.address">
                                </v-textarea>
                            </span>
                        </span>
                        <span v-else>
                            <span><b>{{ permenant.Per_Add_Dynamic.display_name }}</b><span v-if="permenant.Per_Add_Dynamic.isRequired" class="required"> *</span> : {{ permenant.address }}</span>
                        </span>
                            <v-row>
                            <v-col cols="12" sm="6" v-if="permenant.Per_Pin_Dynamic.isActive && permenant.Per_Pin_Dynamic">
                                <span v-if="permenant.Per_Pin_Dynamic.isEditable">
                                    <span v-if="permenant.Per_Pin_Dynamic.isRequired">
                                        <label>{{ permenant.Per_Pin_Dynamic.display_name }}<span class="required">*</span> :</label>
                                        <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="permenant.pin" :rules="[v => !!v || 'Field is required']"></v-text-field>
                                    </span>
                                    <span v-else>
                                        <label>{{ permenant.Per_Pin_Dynamic.display_name }} :</label>
                                        <v-text-field type="number" hint="Only Enter Number Value"  :value="0" min="0" v-model="permenant.pin"></v-text-field>
                                    </span>
                                </span>
                                <span v-else>
                                    <span><b>{{ permenant.Per_Pin_Dynamic.display_name }}</b><span v-if="permenant.Per_Pin_Dynamic.isRequired" class="required"> *</span> : {{ permenant.pin }}</span>
                                </span>
                            </v-col>
                                <v-col cols="12" sm="6"  v-if="permenant.Per_Country_Dynamic.isActive && permenant.Per_Country_Dynamic">
                                    <span v-if="permenant.Per_Country_Dynamic.isEditable">
                                        <span v-if="permenant.Per_Country_Dynamic.isRequired">
                                            <label>{{ permenant.Per_Country_Dynamic.display_name }}<span class="required">*</span> :</label>
                                            <v-select
                                            :items="countryList"
                                            v-model="permenant.cid"
                                            item-text="country"
                                            item-value="id"
                                            :rules="[v => !!v || 'Field is required']"
                                            ></v-select>   
                                        </span>
                                        <span v-else>
                                            <label>{{ permenant.Per_Country_Dynamic.display_name }} :</label>
                                            <v-select
                                            :items="countryList"
                                            v-model="permenant.cid"
                                            item-text="country"
                                            item-value="id"
                                            ></v-select>
                                        </span>
                                    </span>  
                                    <span v-else>
                                        <span><b>{{ permenant.Per_Country_Dynamic.display_name }}</b><span v-if="permenant.Per_Country_Dynamic.isRequired" class="required"> *</span> : {{ permenant.cname }}</span>
                                    </span>
                                </v-col>
                            </v-row>
                       </v-col>
                   </v-row>
                   <v-row>
                       <v-col cols="12" sm="12"  align="center">
                           <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                       </v-col>
                   </v-row>
               </v-card-text>
           </v-card>
    </div>
    </template>
    <script>
    import StudentProfile from './StudentProfile'
    import axios from 'axios'
        export default {
            components:{
                StudentProfile
            },
            data:()=> ({
                ruless: [
            (value) => {
              if (!value) {
                return [];
              } else if (/^\d+$/.test(value)) {
                return "Numeric values are not allowed";
              } else {
                return true;
              }
            },
          ],
          rules: {
            isEmpty: (value) => !!value || 'Field is required',
          },
                snackbar_msg: "",
                color: "",
                snackbar: false,
                search: '',
                countryList:'',
                 local:{
                    address:null,
                    pin:null,
                    cid:null,
                    l_id:null,
                    cname: "",
                    Local_Add_Dynamic: "",
                    Local_Pin_Dynamic: "",
                    Local_Country_Dynamic: "",
                },
                
                permenant:{
                    address:null,
                    pin:null,
                    cid:null,
                    p_id:null,
                    cname: "",
                    Per_Add_Dynamic: "",
                    Per_Pin_Dynamic: "",
                    Per_Country_Dynamic: "",
                },
                checkbox:false,
                savedata:[],
    
        //           pincode: [
        //   (v) => !!v || "pincode is required",
        //   (v) =>  /^-?(0|[1-9]\d*)?$/ || "pincode must be valid",
        //    [v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || 'Number has to be between 0 and 999']
        // ],
        pincode: [
          (v) => !!v || "pincode No is required",
          (v) => (v && v.length == 6) || "Number must be  6 digits",
        ],
                
            }),
            mounted() {
                this.onLoad()
            },
            methods: {
                copyadd()
                {
                    if(this.checkbox==false){
                        this.permenant.address=this.local.address
                    this.permenant.pin=this.local.pin
                    this.permenant.cid=this.local.cid
                    }else{
                        this.permenant.address="";
                    this.permenant.pin="";
                    this.permenant.cid="";
                    }
                    
                },
                onLoad(){
                        axios
                            .post("/LearnerData/learnerAddress")
                            .then(res => {
                                if (res.data.msg == "200") {
                                        this.countryList=res.data.country_List
    
                                        this.local.address=res.data.local_address
                                        this.local.pin=res.data.local_pin
                                        this.local.cid=res.data.local_country_id
                                        this.local.cname=res.data.local_country_name
                                        this.local.l_id=res.data.local_id
                                        this.permenant.address=res.data.permanent_address
                                        this.permenant.pin=res.data.permanent_pin
                                        this.permenant.cid=res.data.permanent_country_id
                                        this.permenant.cname=res.data.permanent_country_name
                                        this.permenant.p_id=res.data.permanent_id
                                        this.local.Local_Add_Dynamic = res.data.Local_Address;
                                        this.local.Local_Pin_Dynamic = res.data.Local_Pin;
                                        this.local.Local_Country_Dynamic = res.data.Local_Country;
                                        this.permenant.Per_Add_Dynamic = res.data.Permanent_Address;
                                        this.permenant.Per_Pin_Dynamic = res.data.Permanent_Pin;
                                        this.permenant.Per_Country_Dynamic = res.data.Permanent_Country;
                                } else {
                                    this.showSnackbar("#b71c1c", "No Data");
                                    //window.console.log(res.data.msg)
                                }
                            })
                            .catch(error => {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                                //window.console.log(error);
                            })
                            .finally(() => {
                                // 'use strict';
                            });
                    },
                showSnackbar(clr, msg) {
                        this.snackbar = true;
                        this.color = clr;
                        this.snackbar_msg = msg;
                    },
                              
                close() {
                        this.dialog = false
                        setTimeout(() => {
                            this.editedItem = Object.assign({}, this.defaultItem)
                            this.editedIndex = -1
                        }, 300)
                    },
                    hasWhiteSpace(s) {
          var status = false;
          var reWhiteSpace = new RegExp(/^\s+$/);
    
          // Check for white space
          if (reWhiteSpace.test(s)) {
            alert("Please Check Your Fields For Spaces");
            //   return true;
            return /^\s/.test(s);
          }
          //   return false;
          return /^\s/.test(s);
        },
                save() {

                    if(this.local.Local_Add_Dynamic.isRequired){
                        if(!this.local.address || /^[ \d]+$/.test(this.local.address) || this.hasWhiteSpace(this.local.address))
                        {
                            this.showSnackbar("#b71c1c", "Enter Local Address...");
                            return
                        }
                    }
    
                    if(this.local.Local_Pin_Dynamic.isRequired){
                        if(this.local.pin==null)
                        {
                            this.showSnackbar("#b71c1c", "Enter Local Address Pincode...");
                            return
                        }
    
                        if(this.local.pin.length!=6)
                        {
                            this.showSnackbar("#b71c1c", " Local Pincode Must Be 6 Digits Only...");
                            return
                        }
                    }
    
                    if(this.local.Local_Country_Dynamic.isRequired){
                        if(this.local.cid==null)
                        {
                            this.showSnackbar("#b71c1c", "Select Local Address Country...");
                            return
                        }
                    }
    
    
                    if(this.permenant.Per_Add_Dynamic.isRequired){
                        if(!this.permenant.address || /^[ \d]+$/.test(this.permenant.address) || this.hasWhiteSpace(this.permenant.address))
                        {
                            this.showSnackbar("#b71c1c", "Enter Permanent Address...");
                            return
                        }
                    }
    
    
                    if(this.permenant.Per_Pin_Dynamic.isRequired){
                        if(this.permenant.pin==null)
                        {
                            this.showSnackbar("#b71c1c", "Enter Permanent Address  Pincode ...");
                            return
                        }
                        if(this.permenant.pin.length!=6)
                        {
                            this.showSnackbar("#b71c1c", " Permanent Pincode must be 6 Digits Only ...");
                            return
                        }
                    }
    
                    if(this.permenant.Per_Country_Dynamic.isRequired){
                        if(this.permenant.cid==null)
                        {
                            this.showSnackbar("#b71c1c", "Select Permenant Address Country...");
                            return
                        }
                    }
                   
                       this.savedata.push(this.local)
                       this.savedata.push(this.permenant) 
                       
                        axios
                            .post("/LearnerData/saveAddress", this.savedata)
                            .then(res => {
                                if (res.data.msg == "200") {
                                    this.onLoad()
                                    this.showSnackbar("#4caf50", "Address Save Successfully..."); // show snackbar on success
                                }
                                else{
                                    this.showSnackbar("#b71c1c", "Something went wrong...");
                                }
                            })
                            .catch(error => {
                                //window.console.log(error);
                            });
                        this.close();
                
                },// end of Save()
            },
        }
        
    </script>
    <style scoped>
    .nav-arrow-left{
            margin-right: 5px;
            text-decoration: none;
            color: #FFF;
        }
        .nav-arrow-right{
            margin: 5px;
            text-decoration: none;
            color: rgb(3, 55, 167);
        }
        .left-arrow{
            color: #FFF;
        }
        .right-arrow{
            color: rgb(3, 55, 167);
        }
    .ec-title{
            background-image: linear-gradient(-100deg, #f4faba, #d64907);
            color: #FFF;
        }
        .c-title{
            background-image: linear-gradient(-90deg, #befcf7, #03948d);
            color: #FFF;
        }
        .tc-title{
            background-image: linear-gradient(-90deg, #FFF, #057996);
            color: #FFF;
            border-radius: 3px;
        }
        .btn-title{
            background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
            color: #FFF;
        }
        .card-title{
             background-image: linear-gradient(-90deg, #8edffd, #034c70);
            color: #FFF;
        }
        .del-card-title{
             background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
            color: #FFF;
        }
        .lbl{
            color: #1705e6;
        }
         .required{
      color:red;
      font-size:14px;
      font-style:bold;
      }
    </style>